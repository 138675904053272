/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';


// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';
.react-toast-notifications__container{
    z-index: 5050!important;
}
img.app-logo-wrap {
    width: 80%;
    margin: 0 auto;
}
.setting-wraper .card-header {
    padding-bottom: 0px !important;
}
.hero-section-wrap.policy-wrap{
    padding-top: 150px;
}
.header.webtopdf-wrap .container {
    margin-top: 130px;
}
.setting-wraper .card-body{
    padding-top: 0px !important;
}
.form-floating label{
    font-size: 12px;
    font-weight: 500;
}
.hero-section-wrap p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: 10px;
    color:#463b94;
}

.policy-wrap li {
    color: #fff;
}
.policy-wrap ul {
    padding: 0px;
}
.policy-wrap h2,
.policy-wrap h3,
.policy-wrap h4 {
    color: #fff;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: 20px;
}
.hero-section-wrap.policy-wrap {
    padding-top: 180px;
    text-align: left;
}
@media only screen and (max-width:767px) {
    .footer-mobile-center {
        text-align:center!important;
    }
    .footer-padding {
        display: block;
        padding-top: 15px!important;
    }
}

// .user-history-table .card-body {
//     overflow: visible;
// }