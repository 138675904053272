@keyframes reactApp_cosmicOrbitLoader_orbit {
    0% {
        transform: rotate(0deg) translateX(50px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(50px) rotate(-360deg);
    }
}

.reactApp_cosmicOrbitLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background: #F0F7FF;
}

.reactApp_cosmicOrbitLoader_centerPoint {
    width: 20px;
    height: 20px;
    background: #E60F00; /* Utilizing the specified color for a central point */
    border-radius: 50%;
    position: absolute;
}

.reactApp_cosmicOrbitLoader_planet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E60F00; /* Applying the specified color to the planets */
    position: absolute;
    animation: reactApp_cosmicOrbitLoader_orbit 4s linear infinite;
}

.reactApp_cosmicOrbitLoader_planet:nth-child(2) {
    animation-duration: 2s;
}

.reactApp_cosmicOrbitLoader_planet:nth-child(3) {
    animation-duration: 5s;
}
