/* GLOBAL SETTING */


/* Reset browser default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Ensures padding and border are included in the element's total width and height */
}

body {
    font-family: "Montserrat", sans-serif;
    background-color: #e7eef8;
}

/* body.home-page::before {
    position: absolute;
    content: "";
    background: url(./images/header-background.png);
    width: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat !important;
    height: 1900px;
    z-index: -1;
} */
.sign-btn.mobile-show {
    display: none;
}

/* Apply to HTML and Body */
html,
body {
    height: 100%;
    font-size: 16px;
    /* Base font size, can be adjusted */
    line-height: 1.6;
    /* Sets comfortable default line height */
    margin: 0;
}

html {
    overflow-x: hidden;
}

/* Global link styling */
a {
    color: inherit;
    /* Makes sure links inherit the color of the text */
    text-decoration: none;
    /* Removes underline from links */
    transition: color 0.3s ease;
    /* Adds smooth color transition for hover effects */
}



#pricing>div>div.packages>div>div:nth-child(1)>div>button {
    margin-top: 113px;
}

/* Typography settings */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 20px;
    font-weight: 700;
    /* Ensures boldness across headings */
}

body h1 {
    text-align: center;
    font-size: 72px;
    line-height: 72px;
    /* Equals 86.4px */
    text-transform: uppercase;
    color: #463b94;
    font-weight: 800;
    padding-bottom: 18px;
}

body h3 {
    color: #463b94;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.1;
    /* 67.2px line height */
}

body h4 {
    font-size: 30px;
    color: #000;
    line-height: 1.2;
    font-weight: 400;
}

p {
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}

/* List Styling */
ul {

    list-style-type: none;
    /* Removes bullet points from unordered lists */
}

li {
    margin-bottom: 10px;
}

/* Button styling */
button,
input[type="submit"] {
    background-color: #03175c;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-btn .circle-button {
    width: 50px;
    height: 50px;
    padding: revert;
}

.circle-button i.fas.fa-user.user-icon {
    position: relative;
    right: 1px;
}

button.pdf-btn:hover {
    background-color: #fff !important;
}

.search-bar input[type="text"]::placeholder {
    color: #fff;
    font-weight: 500;
    letter-spacing: 4px;
    font-size: 16px;
    margin-bottom: 0px;
}

.search-bar input[type="text"] {
    color: #fff;
    font-weight: 500;
    letter-spacing: 4px;
    font-size: 16px;
    padding-left: 40px;
    margin-bottom: 0px;
}

.input-container i.fas.fa-link {
    color: #fff;
    font-size: 30px;
    padding-left: 5px;
}

/* Image settings */
img {
    max-width: 100%;
    /* Makes sure images do not overflow their container */
    height: auto;
    /* Maintains aspect ratio */
    display: block;
    /* Removes extra space below images */
}

/* Global form element styling */
input,
textarea,
select {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #002a56;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0px;
    background-color: #001e3c;
    color: #fff;
}

.date-range-wrap {
    margin-bottom: 20px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px !important;
}

.header ul.login-menu {
    display: none;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.fw-light br {
    display: none;
}

.dropdown button:hover,
.dropdown input[type="submit"]:hover {
    color: #000 !important;
}

.modern-design ul.dropdown-menu.show.dropdown-menu-end button.btn.dropdown-item {
    margin-left: 0px;
    border-radius: 10px;
    background: transparent;
    color: #fff;
}

.modern-design ul.dropdown-menu.show.dropdown-menu-end button.btn.dropdown-item:hover,
.modern-design .dropdown-item:hover {
    background: #463B94 !important;
    border-radius: 5px;
}

.subheader.row.plan-btns {
    padding-left: 0px;
}

.plan-app-wrappers .card {
    margin-bottom: 0;
    border: 0px !important;
}

.plan-app-wrappers button {
    border-radius: 0;
    font-size: 14px;
    font-weight: 700;
    margin-left: 0;
    padding: 14px;
    text-transform: uppercase;
    transition: .3s;
    width: 100%;
    background-color: #463b94;
    border: none;
    color: #fff;
}

.plan-app-wrappers .btn:disabled {
    color: #fff;
    background-color: #463b94;
}

.history-packege .card-body {
    padding-top: 0px;
}

.history-packege .display-6,
.history-packege .display-5 {
    font-size: 18px;
    margin-bottom: 0;
}

.history-packege button {
    margin-left: 0px;
}

.plan-app-wrappers span {
    padding-top: 2px !important;
}

.modern-design .dropdown-item-wrapper:last-child {
    margin-bottom: 0;
}

.modern-design .dropdown-item-wrapper {
    padding: 0;
    margin-bottom: 0;
}

.modern-design .dropdown-menu.show {
    background: #00145A;
    padding: 10px 5px;
}

.modern-design .dropdown-item {
    background: #00145A;
    color: #fff;
}

ul.dropdown-menu.show.dropdown-menu-end .dropdown-item:hover {
    color: #000 !important;
}

.DateInput_input {
    padding: 10px 10px 7px !important;
}

#your_unique_end_date_id {
    padding-left: 22px !important;
}

.circle-button {
    position: relative;
}

ul.dropdown-menus {
    background: #FFFFFF;
    border-radius: 5px;
    left: -100%;
    padding: 10px;
    position: absolute;
}

header.header.webtopdf-wrap .dropdown-menus a {
    background: #0000 !important;
    color: #585858;
    padding: 0 15px !important;
    text-transform: capitalize;
    text-align: left;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
}

.dropdown-item:hover {
    background: #463b94;
    border-radius: 0px;
}

.dropdown-item:hover a {
    color: #fff !important;
}

.dropdown-menus a {
    background: transparent !important;
    color: #fff;
}

button.options-btn:hover {
    background: #fed56c;
}

.footer ul li a:hover {
    color: #00145a;
}

.footer {
    height: auto;
}

.dropdown-item {
    border-bottom: 1px solid #fff;
    border-radius: 0;
    margin-bottom: 10px;
    background: #F0F0F0;
}

.dropdown-item:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
}

.custom-radius {
    border-radius: 0px !important;
}


/* Utility classes */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.hidden {
    display: none;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive adjustments */
@media (max-width: 1600px) {

    body.home-page::before,
    .home-page .footer::after {
        display: none;
    }
}

@media (max-width: 768px) {


    .header nav ul {
        flex-direction: column;
    }

    .steps,
    .feature-list,
    .benefit-list,
    .pricing-options {
        flex-direction: column;
        align-items: center;
    }
}

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #002a56;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: #463b94;
    display: contents;
}

.logo a {
    display: contents;
}

.header,
.footer {
    background: transparent !important;
}

body .header {
    position: relative;
}

.header nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.footer {
    border-top: 0px solid #f8f9fa !important;
}

.accordion-item:not(:last-of-type) .accordion-body::after {
    display: none;
}

.header nav ul li {
    margin: 0;
}

.accordion {
    overflow: unset;

}

.pdf-options label {
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    font-weight: 400;
}

.header nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

/* Toggle Button */
.toggle-button {
    display: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

/* Responsive Design */


@media (max-width: 991px) {
    .toggle-button {
        display: block;
    }

    .header ul.login-menu {
        display: block;
        padding: 0px;
    }

    .logo img {
        width: 100%;
    }

    ul.login-menu {
        position: unset !important;
    }

    .header nav ul {
        /* display: none; */
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #1a294f;
        position: absolute;
        top: 35px;
        left: 0;
        padding: 13px 0;
        z-index: 1;
        border-radius: 10px;
    }

    .navbar-toggler {
        background-color: #e7eef8 !important;
        border-radius: 5px;
        border: 2px solid #463b94;
        float: right;
    }

    .navbar {
        padding: 0;
    }

    .header nav ul li {
        margin: 0px 0;
        padding: 10px;
    }

    li.nav-item {
        background: #f0f0f0;
        margin-bottom: 10px !important;
    }

    .header nav ul.active {
        display: flex;
    }
}

.logo img {
    width: 88%;
}

.header .sign-btn a {
    border-radius: 5px;
    background-color: #463b94;
    padding: 10px 40px !important;
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    transition: .3s ease-in-out;
    cursor: pointer;
}

.sign-btn a:hover {
    background: #00145a;
    color: #fff !important;
}

.sign-btn {
    text-align: right;
    display: block;
    /* width: 100%;
    max-width: 140px;
    float: right; */
    margin-right: 0px;
}


.header.scrolled {
    position: sticky;
    top: 0;
    background-color: #e7eef8 !important;
    height: 80px;
    width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
}

.header.webtopdf-wrap.scrolled .sign-btn a {
    padding: 5px 20px !important;
}

.scrolled .container {
    margin-top: 0 !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-radius: 0 !important;

}

.scrolled .logo img {
    width: 100%;
    max-width: 200px;
}

.header .nav-link {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 30px;
    color: #463b94;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    transition: .3s ease-in-out;
    padding: 0px 9px !important;
}

.header .navbar-nav .nav-link.active,
.header .nav-link:hover {
    color: #00145a;
}

.mobile-div {
    display: none !important;
}

.useage span {
    color: #00145a;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    display: block;
    text-align: center;
}

.map-area {
    padding-top: 10px;
    padding-bottom: 10px;
}

nav.navbar.navbar-expand-lg {
    background-color: transparent;
}

.f-logo {
    padding-bottom: 30px;
    display: inline-block;
}

.footer ul li a {
    color: #463b94;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

#root>footer>div>div>div:nth-child(1)>div.col-md-7>p {
    color: #463b94;
    font-weight: 600;
}

.footer p:last-child {
    color: #000000;
}

.footer .row:last-child {
    border-top: 1px solid #ffffff1a;
    padding: 15px 0px;
}

.footer .row:first-child {
    padding-bottom: 40px;
    align-items: center;
}

.up-arrow img {
    float: right;
}

.footer-links ul {
    display: inline-block;
    padding-left: 70px;
}

.footer-links {
    display: flex;
    justify-content: flex-end;
}


/*hero section css */
.hero-section-wrap {
    padding-top: 290px;
    text-align: center;
    background-image: linear-gradient(180deg, rgba(225, 225, 255, 0.9999999999999999) 0%, rgba(255, 255, 255, 0) 100%);
}

.hero-section-wrap img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.faq-wrap {
    margin-top: 72px;
    background-image: linear-gradient(0deg, rgba(225, 225, 255, 0.9999999999999999) 0%, rgba(255, 255, 255, 0) 100%);
}

.hero-section-wrap p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
    color: #463b94;
    text-transform: uppercase;
}

.hero-section-wrap a {
    color: #463b94 !important;
    font-weight: bold;
    text-decoration: underline;
}

/* Search bar form */
.search-bar {
    background-image: linear-gradient(90deg, #001359 0%, #453b93 100%);
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin-top: 160px !important;
}

.mobile-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url(./images/circle-check.svg) !important;
}

.pdfonline-wrap {
    margin-top: 80px;
}

/* Input field container */
.input-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 10px;
}

/* Icon (placeholder for an actual link icon) */
.icon-link {

    background-size: 20px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

/* Input field */
.search-bar input[type="text"],
.contact-form input[type="text"] {
    border: none;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
    background: transparent;
}

button.pdf-btn {
    color: #00145a;
    margin-left: 10px;
    margin-right: 10px;
    background: #FFD66C !important;
}

/* Button styling */
button {
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
}

/* Options button */
.options-btn {
    background-color: #e5e5e5;
    color: #00145a;
}

/* PDF button */
.pdf-btn {
    background-color: #463b94;
    color: white;
}

/* Responsiveness */
@media (max-width: 768px) {
    .search-bar {
        padding: 8px;
    }

    input[type="text"] {
        font-size: 14px;
    }

    button {
        padding: 8px 15px;
        font-size: 12px;
    }

    .pdf-btn {
        background-color: #463b94;
    }
}

@media (max-width: 480px) {
    .search-bar {
        flex-direction: column;
        padding: 15px;
    }

    button {
        width: 100%;
        margin: 5px 0;
    }
}

.search-bar button {
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 15px 39px;
    text-transform: uppercase;
    background: #fff;
}

/*pdf online section */
.new-section {
    background: #fff;
    border-top: 1px solid #DDDDDD;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

.container.conversion-options .options-area {
    padding: 48px 30px 30px 30px;
}

.footer-modal {
    border-top: 1px solid #CCCCCC;
    padding: 30px;
}

.map-area input,
.quality-area input {
    margin-right: 10px;
}

.conversion-options h4 {
    font-size: 16px;
    line-height: 36px;
    color: #000000;
    font-weight: 700;
    padding-top: 28px;
    margin-bottom: 0;
}

.form-check-input:checked {
    background-color: transparent;
    border-color: transparent;
}

#conversation-options .form-check-input:checked {
    background-color: transparent;
    border-color: transparent;
}

.check-box-wrap {
    padding-top: 10px;
}

.new-user-form label.form-label {
    padding-left: 20px;
}

#portal-root li.list-group-item {
    border-radius: 5px !important;
    border: 1px solid #DDDDDD;
}

.responsive-text {
    font-size: 18px;
    line-height: 30px;
    color: #000 !important;
    font-weight: 400;
}

.pdf-progress-container .responsive-text {
    color: #fff !important;
}

h6.mt-2.progress-text.text-white {
    font-size: 14px;
    letter-spacing: 1px;
}

.col-auto.text-center.pt-3 {
    position: relative;
}

.spinner-border {
    color: #463b94 !important;
}

.spinner-progress-count {
    top: 54px;
    left: 50%;
    color: #fff;
}

.gen-btn button.ml-2.mb-2 {
    background: #463b94;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat';
    letter-spacing: 1px;
    padding: 10px 25px;
    border-radius: 5px;
    color: #fff;
}

.gen-btn button.ml-2.mb-2:hover {
    background: #00145a;
    color: #fff !important;
}

.circle-button img {
    border: 2px solid #463b94;
    margin-top: 8px;
}

.responsive-text a {
    color: #463b94 !important;
    font-weight: 700;
}

.package h3 {
    padding-bottom: 10px;
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 0;
}

button.btn.btn-primary.mr-2,
button.btn.btn-secondary {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    border: 0px;
    border-radius: 5px;
    background-color: #463b94;
    padding: 10px 46px;
    transition: .3s;
}

button.btn.btn-primary.mr-2:hover,
button.btn.btn-secondary:hover {
    background-color: #00145a;
    color: #fff;
}

.conversion-options h5 {
    font-size: 24px;
    line-height: 30px;
    color: #463b94;
    font-weight: 800;
    padding-bottom: 20px;
}


.pdf-options .form-control {
    border-radius: 0px;
    background: #EAEAEA;
    height: 50px;
}

.check-box-wrap label {
    padding-left: 10px;
}

.wrapper footer.footer {
    display: inherit;
    padding-top: inherit;
}

.map-area label,
.quality-area label {
    padding-bottom: 0px;
    padding-top: 0px;
}

.option input[type="radio"] {
    accent-color: #463b94;
    /* Green color for selected radio button */
}

.option input[type="radio"]:checked {
    background-color: #463b94;
    /* Same color when checked */
}

/* Chrome and WebKit-based browsers */
.input-container input:-webkit-autofill {
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
    /* Changes the text color */
}

/*
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    color: #fff; /* Ensure the text remains visible 
} */
.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #000;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: transparent;
}

.form-check-input.is-valid:focus {
    box-shadow: unset;
}

/* Firefox (partial support for the internal autofill) */

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
    color:#ffff;
} */
.scan-btn {
    width: 100%;
    margin-bottom: 20px;
}

#sitemap-model td:first-child {
    width: 38px;
    vertical-align: middle;
}

#sitemap-model tr {
    vertical-align: middle;
}

#sitemap-model td:first-child input {
    margin-top: 0px !important;
}

#sitemap-model tr:first-child th input {
    margin-top: 0px !important;
}

.add-btn {
    background: #463b94;
    border: 1px solid #463b94;
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(./images/square-check.svg) !important;
}

.modern-design .content .form-switch .form-check-input:checked {
    border: 1px solid #6c5dd3 !important;
    background-color: #463b94;
}

.modern-design .dashboard-table li.dropdown-item-wrapper {
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}

.modern-design .dashboard-table li.dropdown-item-wrapper .dropdown-item {
    padding-left: 10px;
    background: #463B94;
    color: #fff !important;
}

.modern-design .dashboard-table li.dropdown-item-wrapper:last-child {
    padding-bottom: 0;
}

.modern-design ul.dropdown-menu.show.dropdown-menu-end .dropdown-item:hover {
    color: #fff !important;
    background: #00145A;
}

.dropdown-menu.show {
    padding: 0px;
}

.form-check-input:focus {
    box-shadow: unset;
}

input#colorPicker {
    padding: 0px;
    width: 20%;
    display: inline-block;
    border: 0px;
    float: right;
}

input#colorlayer- {
    padding: 0;
    width: 23%;
    border: 0px;
    float: right;
}

input#watermarkColor- {
    padding: 0;
    width: 23%;
    border: 0px;
    float: right;
}

.webtopdf-wrap {
    height: 0;
}

button.btn.btn-secondary.ms-auto.scan-btn {
    width: 30%;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
}

button.btn.btn-secondary.ms-auto.scan-btn-add {
    width: 44%;
    float: right;
    margin-bottom: 10px;
    margin-top: 10px;
}

#sitemap-model a.btn.btn-primary,
#bulkData-model button.btn.btn-danger.mr-2 {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    border: 0px;
    border-radius: 5px;
    background-color: #463b94;
    padding: 10px 46px;
    -webkit-transition: .3s;
    transition: .3s;
}

#sitemap-model a.btn.btn-primary:hover {
    background-color: #000;
    color: #fff;
}

#sitemap-model input#isCheckedAll {
    margin-top: 20px;
}

#sitemap-model td.text-end {
    padding-right: 40px;
}

#sitemap-model th.text-end {
    padding-right: 80px;
}

.pdfonline-wrap h2 {
    color: #463b94;
    font-size: 36px;
    font-weight: 500;
    padding-bottom: 60px;
    padding-top: 15px;
    line-height: 48px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    gap: 30px;
    align-content: center;
}

.btn-close:hover {
    background-color: transparent !important;
}

.grid-item {
    background-image: linear-gradient(135deg, #001359 0%, #453b93 100%);
    border-radius: 0px;
    text-align: left;
    padding-left: 45px;
    padding-right: 40px;
    padding-bottom: 30px;
}

.packages-section h3 {
    padding-top: 15px;
    font-size: 48px;
    font-weight: 400;
    padding-bottom: 28px;
}

.packages-section h4 {
    padding-bottom: 15px;
}

.grid-item h2 {
    font-size: 48px;
    color: #ffd66c;
    margin-bottom: 0px;
    padding-bottom: 20px;
    font-weight: 500;
    padding-top: 40px;
}

.grid-item:nth-child(even) {
    background-color: #463b94;
    color: #6FE291;
}

.image-section {
    display: flex;
    background-image: linear-gradient(135deg, #e1e1ff 0%, #ffffff 100%);
    align-items: center;
    justify-content: center;
}

.image-section img {
    display: block;
    height: max-content;
}


.grid-item p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .grid-item {
        font-size: 0.9rem;
    }

    .grid-item h2 {
        font-size: 2rem;
    }
}

.experience-wrap {
    padding-top: 24px;
}

.experience-wrap h4 {
    padding-top: 27px;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 24px;
}

.experience-wrap h3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
}

.experience-wrap p {
    padding-right: 0px;
    color: #000;
    line-height: 30px;
    font-weight: 500;
    font-size: 15px;
}

.chose-wrap span {
    font-size: 16px;
    color: #463b94;
    font-weight: bold;
    padding-bottom: 5px;
    display: block;
}

.chose-wrap {
    padding-top: 55px;
    padding-bottom: 30px;
}

.chose-wrap h3 {
    padding-bottom: 15px;
    font-weight: 400;
}

.chose-wrap p {
    font-weight: 500;
    width: 100%;
    padding-bottom: 20px;
    color: #000;
    line-height: 30px;
    font-size: 15px;
}

.feature-box h5 {
    font-size: 16px;
    color: #463b94;
    line-height: 1.2;
    font-weight: 600;
    padding-bottom: 22px;
}

.feature-wrap h3 {
    padding-bottom: 50px;
}

.feature-box p {
    font-weight: 500;
    line-height: 30px;
    color: #000;
}

.feature-wrap .col-md-3:last-child .feature-box {
    padding-left: 42px;
}

.feature-wrap .col-md-3 {
    position: relative;
    margin-bottom: 45px;
}

.feature-wrap .col-md-3::before {
    position: absolute;
    content: "";
    background: #00145a;
    width: 1px;
    height: 100%;
    right: 10px;
}

.feature-wrap .col-md-3:last-child::before {
    display: none;
}

.feature-wrap h4 {
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: 1.2;
}

.feature-wrap h4:nth-child(6) {
    padding-top: 30px;
}

.feature-wrap .col-md-3:nth-child(3) .feature-box,
.feature-wrap .col-md-3:nth-child(4) .feature-box {
    padding-left: 38px;
}

.feature-wrap .col-md-3:nth-child(4)::before {
    right: -20px;
}

.feature-wrap .col-md-3:nth-child(5)::before {
    display: none;
}

.feature-wrap .col-md-3:nth-child(5) .feature-box {
    padding-left: 70px;
}

.feature-wrap .col-md-3:last-child .feature-box p {
    padding-right: 0;
}

.form-check-input {
    --bs-form-check-bg: #fff;
    border: 1px solid #463B94;
}

.virtual-wrap span {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    padding-bottom: 20px;
    display: block;
}

.virtual-wrap {
    margin-top: 27px;
    background-image: linear-gradient(90deg, #001359 0%, #453b93 100%);
    padding: 45px 0px;
}

.virtual-wrap h3 {
    padding-bottom: 30px;
    color: #ffdc50;
    margin-bottom: 0;
    font-size: 48px;
}

.virtual-wrap h4 {
    padding-bottom: 30px;
    color: #fff;
    margin-bottom: 0;
}

.virtual-wrap img {
    float: right;
}

.benefits-wrap p {
    color: #000;
}

.virtual-wrap p {
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
}

.benefits-wrap {
    margin-top: 85px;
}

.benefits-wrap h3 {
    padding-bottom: 12px;
    font-size: 48px;
}

.benefit-box {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.plan-details {
    text-align: center;
    padding-top: 17px;
}

.plan-details h3 {
    padding-bottom: 39px;
}

.plan-details h4 {
    padding-bottom: 22px;
}

.benefit-details span {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 20px;
    display: block;
}

.benefit-details p {
    font-weight: 500;
    margin-bottom: 0;
}

.benefit-details {
    padding-left: 30px;
}

.user-wrap {
    text-align: center;
}

.user-wrap {
    padding-top: 64px;
}

.user-wrap h3 {
    font-size: 48px;
    padding-bottom: 10px;
}

.testimonial-content i.fa-regular.fa-user {
    font-size: 36px;
    color: #463B93;
    padding-bottom: 40px;
}

.user-wrap p {
    padding-bottom: 0px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000;
    margin-bottom: 0px;
}

.broswer-wrap {
    text-align: center;
    margin-top: 26px;
}

/* Icon container styles */
.icon-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 45px 0px;
}

.broswer-wrap .container {
    background-image: linear-gradient(0deg, #001359 0%, #453b93 100%);
    padding-top: 70px;
    padding-bottom: 37px;
}

.broswer-wrap h3 {
    color: #ffd66c;
    font-size: 48px;
    font-weight: 400;
    padding-bottom: 15px;
}

.broswer-wrap p {
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 22px;
}

/* Icon styles */
.icon {
    width: 100px;
    height: 100px;
    margin: 0 10px;
}

/* Responsive media query */
@media (max-width: 768px) {
    .icon-container {
        padding: 10px;
        border-radius: 30px;
    }

    .icon {
        width: 40px;
        height: 40px;
        margin: 5px;
    }
}

@media (max-width: 480px) {
    .icon-container {
        padding: 8px;
        border-radius: 20px;
    }

    .icon {
        width: 30px;
        height: 30px;
        margin: 3px;
    }
}

/* slider css */
.testimonial-slider {
    margin: 30px auto;

}

.rounded-circle {
    width: 40px;
    height: 40px;
}

.slick-dotted.slick-slider {
    padding-left: 0px;
}

.testimonial-item {
    background-color: transparent;
    /* Dark background similar to your image */
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
}

.f-logo img {
    width: 100%;
}

.testimonial-slider .slick-dots li button:before {
    color: #999999;
    opacity: 1;
    font-size: 10px;
}

.testimonial-slider .slick-dots {
    bottom: -5px;
}

.testimonial-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #463B94;
}

.slick-dotted.slick-slider {
    width: 99%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-top: 0px;
}

.user-wrap img {
    margin: 0 auto;
}

.application-history h4 {
    color: #000;
}

.plan-app-wrappers h3 {
    color: #323232;
    font-size: 24px;
    margin: 0;
}

.plan-app-wrappers ul {
    padding-top: 10px;
}

.plan-app-wrappers span {
    font-size: 26px;
}

.plan-app-wrappers li {
    color: #323232;
}

button.btn.btn-lg.btn-light-danger.w-100 {
    margin-left: 0 !important;
}

.testimonial-item::before {
    position: absolute;
    content: "";
    background-color: #463b94;
    width: 1px;
    height: 100%;
    right: 1px;
    border-radius: 10px;
    max-height: 470px;
}

.testimonial-item h3 {
    font-size: 16px;
    color: #463b94;
    font-weight: 600;
    margin-bottom: 0;
}

.testimonial-item p {
    font-size: 16px;
    padding-bottom: 25px;
    font-weight: 400;
    line-height: 1.6;
}

.testimonial-item p:last-child {
    font-size: 14px;
    font-weight: 600;
}

.slick-dots li button:before {
    color: #463b94;
    /* Green color for the dots */
}

.slick-dots li {
    margin: 0px;
}

.image-section img {
    width: 45%;
}

.tab-container {
    background-color: #001F54;
    color: #fff;
    width: 400px;
    border-radius: 15px;
    padding: 10px 50px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.packages-section p {
    padding-bottom: 33px;
    color: #000;
    font-weight: 500;
    line-height: 30px;
}

.tabs {
    display: inline-flex !important;
    border: 3px solid #463b94;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-bottom: 58px;
}

.packege-price h3 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 0;
    padding-top: 22px;

}

.package img {
    padding-bottom: 40px;
    padding-top: 15px;
}

.packages .col-md-4.col-sm-12.col-12:nth-child(2) .packege-header {
    background-color: #00145a;
}

.packages .col-md-4.col-sm-12.col-12:nth-child(2) .packege-detail {
    background-image: linear-gradient(0deg, #d7e1ff 0%, #ffffff 100%);
}

.packages .col-md-4.col-sm-12.col-12:nth-child(2) .package li::before {
    background: #0D190D;
}

.tab-link {
    background-color: #001F54;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 50%;
}

.tab-link.active {
    background-color: #32CD32;
}

.tab-content {
    display: none;
    text-align: center;
}

.cta-button {
    background-color: #32CD32;
    color: #001F54;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.pdf-progress-container {
    background: #1a284a;
    border-radius: 10px;
}

.cta-button:hover {
    background-color: #fff;
    color: #001F54;
    transition: 0.3s;
}

.faq-wrap .accordion-item,
.faq-wrap .accordion-header,
.faq-wrap button.accordion-button {
    background: transparent;
    border: 0;
    box-shadow: unset;
}

.faq-wrap button.accordion-button,
.faq-wrap .accordion-body {
    padding: 0;
    color: #000 !important;
}

.faq-wrap button.accordion-button {
    color: #000 !important;
    font-weight: 600;
    margin-left: -14px;
}

.accordion-item .accordion-header {
    height: 34px;
}

.reach-wrap p {
    padding-bottom: 40px;
    color: #000;
}

.accordion-item {
    padding-bottom: 0px;
}

button.accordion-button {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
}

.accordion-body {
    font-size: 16px;
    line-height: 30px;
    padding: 30px 0px !important;
    padding-bottom: 10px !important;
}

.faq-wrap .accordion-button::after {
    display: none;
}

div#accordionExample {
    padding-left: 33px;
}

.accordion-button i.fas.fa-angles-right {
    color: #463B94;
    font-size: 16px;
    position: relative;
    left: -17px;
}

.faq-wrap h3 {
    padding-bottom: 40px;
    margin-bottom: 0;
    font-size: 48px;
}

.faq-wrap p {
    padding-bottom: 43px;
    color: #000;
    font-weight: 500;
}

.accordion-button i.fas.fa-angles-right {
    transform: rotate(90deg);
}

button.accordion-button.collapsed i.fas.fa-angles-right {
    transform: rotate(0deg);
}

.accordion-body ul li::marker {
    color: #463b94;
}

.accordion-body ul {
    padding-top: 18px;
}

.accordion-body ul li {
    list-style: disc;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.7;
}


/*form css */

.contact-form .form-row {
    display: flex;
    justify-content: space-between;
}

.contact-form .form-group {
    width: 51%;
    margin-bottom: 10px;
}

.contact-form .form-group label {
    display: block;
    margin-bottom: 0px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
}

.contact-form .form-group:nth-child(3),
.contact-form .form-group:nth-child(2) {
    width: 100%;
}

.contact-form .form-row .form-group:last-child {
    width: 48%;
}

.benefits-wrap .row:last-child {
    padding-top: 75px;
}

.reach-wrap {
    padding-top: 70px;
    background-image: linear-gradient(180deg, rgba(225, 225, 255, 0.9999999999999999) 0%, rgba(255, 255, 255, 0) 100%);
}

.reach-wrap h3 {
    padding-bottom: 24px;
    font-size: 48px;
}



.apex-chart .apexcharts-menu {
    background: #fff;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
    width: 100%;
    background-color: #fff;
    border: none;
    color: #463b94 !important;
    font-size: 16px;
    border-radius: 5px;

}

.contact-form .form-group input {
    background-color: #fff;
    padding: 12px 10px;
    font-size: 14px;
    font-weight: 600;
}

.contact-form .form-group input::placeholder,
.contact-form .form-group textarea::placeholder {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #463b94;
}

.contact-form .form-group input:focus,
.contact-form .form-group textarea:focus {
    border-color: transparent;
    outline: none;
}

.benefit-box {
    background-image: linear-gradient(90deg, #e1e1ff 0%, #d7e1ff 100%);
    padding: 38px;
    margin-bottom: 10px;
}

#benefit>div>div:nth-child(2)>div:nth-child(1) {
    padding-right: 0px;
}

#benefit>div>div:nth-child(2)>div:nth-child(2)>div:nth-child(1),
#benefit>div>div:nth-child(2)>div:nth-child(2)>div:nth-child(2) {
    background-image: linear-gradient(90deg, #e1e1ff 0%, #d7e1ff 100%);
}

.contact-form .btn-submit {
    background-color: #463b94;
    color: #fff;
    border: none;
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s;
    margin-top: 32px;
    margin-left: 0px;
}

.contact-form .btn-submit i {
    margin-left: 20px;
    font-size: 30px;
}

.contact-form .btn-submit:hover {
    background-color: #00040e;
}

.footer {
    padding-top: 100px;
    display: block;
    position: relative;
}

/* Basic layout and styles */
.packages-section {
    text-align: center;
    padding: 50px 0px;
}



.tab {
    padding: 14px 60px;
    margin: 0px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    border-radius: 0px;
    font-weight: bold;
}

.tab.active {
    background-color: #463B94;
    color: #fff;
}

.tab.active:hover {
    background-color: #00145a;
    color: #fff !important;
}

.tab:hover {
    background: #463b94;
    color: #fff !important;
}


.package {
    text-align: left;
}

.packege-header {
    display: flex;
    justify-content: space-between;
    background-color: #463b94;
    padding: 30px 15px;
    padding-bottom: 22px;
}

.package .price {
    font-size: 18px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 0px;
}

.packege-price {
    text-align: left;
}

.package span {
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
    display: block;
    padding-bottom: 0px;
    text-transform: uppercase;
}

.package.card-stretch-full:hover .packege-header {
    background-color: #00145a;
}

.package.card-stretch-full:hover .packege-detail {
    background-image: linear-gradient(0deg, #d7e1ff 0%, #ffffff 100%);
}

.package.card-stretch-full:hover button.subscribe-btn {
    background-color: #00145a;
    color: #fff;
}

.package ul {
    list-style-type: none;
    padding: 0;
}

.package li {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 400;
    padding-left: 0px;
    position: relative;
    text-transform: capitalize;
    line-height: 24px;
    color: #000;
}

.package li::before {

    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    left: -18px;
    top: 11px;
    background-color: #564c9d;
    border-radius: 10px;
}

.packege-detail ul span ul li ul {
    padding-left: 20px;
}

li:has(strong)::before {
    display: none;
}

.package li strong {
    padding: 5px 0px;
    display: block;
    font-size: 14px;
    color: #463b94;
    font-weight: 700;
}

.admin-dashboard .apexcharts-toolbar {
    top: -65px !important;
}

.dashboard-table button {
    padding-left: 0px;
    margin-left: 0px;
}

button.subscribe-btn {
    width: 100%;
    border-radius: 0px;
    margin-left: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .3s;
    padding: 14px;
}

.subscribe-btn {
    background-color: #463b94;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}



/* Flip Animation */
.package.flip {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.packege-detail {
    background-image: linear-gradient(0deg, #e1e1ff 0%, #ffffff 100%);
    padding: 30px 15px;
    min-height: 885px;
}

.package.flip h3,
.package.flip .price,
.package.flip ul,
.package.flip .subscribe-btn {
    opacity: 0;
    transition: opacity 0.5s;
}

.package.flip h3,
.package.flip .price,
.package.flip ul,
.package.flip .subscribe-btn {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
}



@media (max-width: 768px) {
    .contact-form .form-row {
        flex-direction: column;
    }

    .contact-form .form-group {
        width: 100%;
    }
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.image-section img {
    animation: moveUpDown 3s ease-in-out infinite;
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }
}

.img-div img {
    animation: fadeInOut 3s infinite;
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.icon-container .icon {
    animation: pulse 2s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.up-arrow img {
    animation: bounce 5s infinite;
}

@media only screen and (max-width:1440px) {
    .hero-section-wrap {
        padding-top: 200px;
    }

    .conversion-options h5 {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .container.conversion-options .options-area {
        padding: 30px 30px 30px 30px;
    }

    .search-bar {
        margin-top: 90px !important;
    }

    .pdfonline-wrap {
        margin-top: 50px;
    }

    .pdfonline-wrap h2 {
        margin-bottom: 0px;
    }
}

@media only screen and (max-width:1370px) {
    ul.dropdown-menus {
        left: -123px;
    }
}

@media only screen and (max-width:1201px) {
    br {
        display: none;
    }

    .footer .row:first-child {
        padding-bottom: 10px;
    }

    .header .nav-link {
        padding: 3px 0px !important;
        line-height: 15px;
    }

    #pricing>div>div.packages>div>div:nth-child(1)>div>button {
        margin-top: 138px;

    }

    .login-menu a.nav-links {
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: #000;
    }

    .login-menu li.nav-items {
        line-height: 21px;
        background: #f0f0f0;
        margin-bottom: 10px;
        color: #000;

    }

    .hero-section-wrap img {
        padding-bottom: 0px;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 15px;
    }

    .feature-box {
        padding-right: 14px;
    }

    .feature-wrap .col-md-3:nth-child(4)::before {
        right: 6px;
    }

    .grid-item {
        padding-left: 25px;
        padding-right: 25px;
    }

    .grid-item h2 {
        font-size: 42px;
        padding-top: 20px;
    }

    .chose-wrap p {
        width: 100%;
    }

    .feature-wrap .col-md-3:nth-child(5) .feature-box,
    .feature-wrap .col-md-3:nth-child(3) .feature-box,
    .feature-wrap .col-md-3:nth-child(4) .feature-box {
        padding-left: 0px;
    }

    .feature-wrap h4 {
        font-size: 18px;
    }

    .testimonial-slider .slick-dots {
        bottom: -45px;
    }

    .testimonial-item::before {
        height: 90%;
    }

    .feature-box h5 {
        font-size: 18px;
        padding-bottom: 0px;

    }

    br {
        display: none;
    }

    .feature-wrap .col-md-3::before {
        right: 5px;
    }

    .feature-box p,
    .benefit-details p {
        font-size: 14px;
    }

    .benefit-details span {
        font-size: 16px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .user-wrap p {
        font-size: 18px;
    }

    .icon {
        width: 50px;
        height: 50px;
        margin: 0 0px;
    }

    .experience-wrap p {
        padding-right: 0px;
    }

    .grid-item h2 {
        padding-bottom: 5px;
    }

    .packege-detail {
        min-height: 980px;
    }

    .package .price {
        font-size: 16px;
    }

    .packege-price h3 {
        font-size: 20px;
    }

    .benefit-details {
        padding-right: 50px;
    }

    .benefit-box {
        padding: 19px;
    }

    .accordion-item .accordion-header {
        height: auto;
    }

    .footer-links ul {
        padding-left: 40px;
    }

    .pdf-options label {
        font-size: 10px;
    }

    .conversion-options h5 {
        font-size: 24px;
        padding-bottom: 0px;
        margin-bottom: 0;
    }

    .container.conversion-options .options-area {
        padding: 29px 30px 30px 30px;
    }

    .responsive-text {
        font-size: 14px;
    }
}

@media only screen and (max-width:1100px) {

    .footer-links ul {
        padding-left: 20px;

    }

    .footer p .footer ul li {
        font-size: 14px;
    }

    .f-logo {
        padding-bottom: 20px;
    }

    .footer .row:last-child {
        padding: 30px 0px;

    }
}

@media (max-width:1024px) {
    .pdf-options label {
        font-size: 10px;
    }

    button.btn.btn-secondary.ms-auto.scan-btn,
    button.btn.btn-secondary.ms-auto.scan-btn-add {
        padding-left: 0px;
        padding-right: 0px;
    }

    .conversion-options h4 {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .pdfonline-wrap {
        margin-top: 20px;
    }

    .pdfonline-wrap h2 {
        padding-bottom: 40px;
        font-size: 28px;
    }

    .grid-item h2 {
        padding-bottom: 15px;
    }

    .packages-section h3 {
        font-size: 28px;
        margin-bottom: 0;
    }

    body h4 {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .grid-item {
        padding-left: 20px;
        padding-right: 20px;
    }

    .package h3 {
        font-size: 18px;
    }

    .package .price {
        font-size: 14px;
        padding-bottom: 0px;
    }

    .hero-section-wrap {
        padding-top: 160px;
    }

    .experience-wrap h3 {
        font-size: 28px;
        margin-bottom: 0;
    }

    .experience-wrap {
        padding-top: 0px;
    }

    .experience-wrap h4 {
        font-size: 18px;
        padding-top: 15px;
    }

    .chose-wrap {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .chose-wrap h3,
    .feature-wrap h3,
    .virtual-wrap h3,
    .benefits-wrap h3,
    body h3,
    .user-wrap h3,
    .broswer-wrap h3,
    .faq-wrap h3,
    .reach-wrap h3 {
        font-size: 28px;
    }

    .reach-wrap {
        padding-top: 50px;
    }

    .virtual-wrap h3,
    .virtual-wrap h4,
    .benefits-wrap h3,
    .user-wrap h3,
    .broswer-wrap h3,
    .faq-wrap h3,
    .faq-wrap p {
        padding-bottom: 10px;
    }

    .accordion-body {
        padding: 10px 0px !important;
    }

    .benefits-wrap {
        margin-top: 60px;
    }

    .faq-wrap {
        margin-top: 40px;
    }

    .benefits-wrap h3,
    .user-wrap h3,
    .reach-wrap h3,
    .broswer-wrap h3 {
        margin-bottom: 0;
    }

    .benefits-wrap .row:last-child {
        padding-top: 25px;
    }

    .feature-wrap h3 {
        padding-bottom: 20px;
    }

    .feature-box {
        padding-right: 15px;
    }

    .chose-wrap p {
        margin-bottom: 0;
    }

    body h1 {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 0;
    }

    .hero-section-wrap p {
        font-size: 22px;
    }

    .search-bar {
        margin-top: 60px !important;
    }

    .testimonial-item h3 {
        font-size: 16px;
        padding-bottom: 0;
    }

    .feature-wrap h4 {
        font-size: 16px;
    }

}

@media only screen and (max-width:991px) {
    body.home-page {
        overflow-x: hidden;
    }

    button.btn.btn-secondary.ms-auto.scan-btn-add,
    button.btn.btn-secondary.ms-auto.scan-btn {
        width: 50%;
    }

    .package {
        margin-bottom: 20px;
    }

    .header .sign-btn {
        display: none;
    }

    .broswer-wrap {
        margin-top: 46px;
    }

    .icon-container {
        margin: 0px 0px;
    }

    .broswer-wrap .container {
        padding-top: 45px;
        padding-bottom: 26px;
    }

    .broswer-wrap p {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .experience-wrap p,
    .chose-wrap p,
    .feature-box p,
    .virtual-wrap p,
    .broswer-wrap p {
        line-height: 24px;
    }

    .benefit-details span {
        padding-top: 0;
    }

    .user-wrap {
        padding-top: 34px;
    }

    .mobile-wraper,
    nav.navbar.navbar-expand-lg.justify-content-center {
        display: block;
    }

    .sign-btn {
        max-width: 95px;
    }

    .header.webtopdf-wrap .container {
        margin-top: 80px;
    }

    .package li {
        font-size: 12px;
        line-height: 18px;

    }

    .package img {
        padding-bottom: 10px;
    }

    .packege-detail {
        min-height: 510px;
        padding: 20px 10px;
    }

    #pricing>div>div.packages>div>div:nth-child(3)>div>div.packege-detail {
        min-height: 579px;
    }

    button.subscribe-btn {
        padding: 10px;
        ;
    }

    .experience-wrap h3 {
        line-height: 38px;
    }

    .chose-wrap h3 {
        margin-bottom: 0;
    }

    .feature-wrap h4:nth-child(6) {
        padding-top: 0px;
        padding-bottom: 10px;
    }

    .benefit-details {
        padding-right: 0px;
    }

    .user-wrap p {
        line-height: 24px;
    }

    .experience-wrap {
        padding-top: 5px;
    }

    .packege-header {
        padding: 15px 15px;
        ;
    }

    .packege-header {
        align-items: center;
    }

    .package span {
        padding-top: 0px;
    }

    .package li {
        font-size: 12px;

    }

    .map-area input,
    .quality-area input {
        margin-right: 7px;
    }

    #pricing>div>div.packages>div>div:nth-child(1)>div>button {
        margin-top: 140px;
    }

    .map-area .col-md-4:first-child {
        width: 40%;
    }

    .map-area .col-md-4:last-child {
        width: 25%;
    }

    .map-area .col-md-4:nth-child(2) {
        width: 35%;
    }

    .scrolled .logo img {
        width: 85%;

    }

    .map-area .col-md-4 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .map-area label.col-sm-3.col-form-label {
        width: 19%;
    }

    .pdfonline-wrap h2 {
        padding-bottom: 30px;
    }

    .grid-item h2 {
        padding-bottom: 10px;
    }

    .header .nav-link:hover {
        color: #343a40;
    }

    .header .nav-link {
        color: #000;
    }

    .header nav ul {
        display: block;
        padding: 10px;
        background: #fff;
    }

    .login-menu li.nav-items:last-child {
        margin-bottom: 0px;
    }

    .container.conversion-options {
        max-width: auto;
    }

    .header nav ul li:hover {
        background-color: #463b94;
        color: #fff !important;
    }

    .header nav ul li:hover a {
        color: #fff !important;
    }

    .pdfonline-wrap {
        margin-top: 40px;
    }

    .chose-wrap {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .img-div img {
        width: 130px;
    }

    .benefit-details {
        padding-left: 15px;
    }

    .benefit-box {
        padding: 15px;

    }

    .user-wrap p {
        font-size: 14px;
    }

    .plan-details h3,
    .plan-details h4 {
        padding-bottom: 15px;
    }

    .icon-container {
        padding: 15px;
        border-radius: 50px;
        margin: 30px 0px;
    }

    .faq-wrap h3 {
        padding-bottom: 15px;
    }

    .accordion-body {
        padding: 0px 0px !important;
        font-size: 14px;
        line-height: 24px;
    }

    .footer-links ul {
        padding-left: 12px;
    }

    .contact-form .form-row .form-group:last-child {
        width: 100%;
    }

    .footer {
        padding-top: 50px;
    }

    .footer ul li a,
    .footer p:last-child {
        font-size: 14px;
    }

    .footer .row:last-child {
        padding: 10px 0px;
    }

    body h1 {
        font-size: 52px;
    }

    body h3 {
        font-size: 28px;
    }

    body h4 {
        font-size: 22px;
    }

    .experience-wrap h4 {
        padding-top: 14px;
        padding-bottom: 10px;
    }

    .search-bar input[type="text"] {
        padding-left: 20px;
    }

    .grid-item p {
        font-size: 14px;
        line-height: 20px;
    }

    .packages-section h3 {
        padding-bottom: 0px;
        padding-top: 0px;
        font-size: 16px;

    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .grid-item {
        padding-left: 15px;
        padding-right: 15px;
    }

    .feature-box h5 {
        font-size: 14px;
    }

    .virtual-wrap h3 {
        padding-bottom: 20px;
    }

    .sign-btn.mobile-show {
        display: block !important;
        max-width: 100%;
        margin: 0px;
    }

    .sign-btn.mobile-show img {
        display: none;
    }

    .sign-btn.mobile-show button.circle-button {
        display: none;
    }
}

@media only screen and (max-width:768px) {
    body h1 {
        font-size: 42px;
    }

    .hero-section-wrap p {
        font-size: 16px;
    }

    .hero-section-wrap {
        padding-top: 130px;
    }

    .conversion-options h5 {
        font-size: 18px;
    }

    .responsive-text {
        font-size: 11px;
    }

    button.btn.btn-primary.mr-2,
    button.btn.btn-secondary {
        padding: 5px 30px;
        ;
    }

    .pdfonline-wrap {
        margin-top: 10px;
    }
}

@media only screen and (max-width:767px) {
    .footer-links {
        justify-content: center;
    }

    #your_unique_end_date_id {
        padding-left: 10px !important;
    }

    body.home-page {
        overflow-x: hidden;
    }

    .fw-light br {
        display: block;
    }

    .DateRangePickerInput_arrow {
        margin-left: 20px;
    }

    .DateInput {
        width: 100% !important;
    }

    .header+.content {
        padding-top: 1rem;
    }

    .plan-app-wrappers .col-md-4 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .mar-wrape .col-sm-3 {
        margin-bottom: 10px;
    }


    .mobile-show img {
        display: none !important;
    }

    .container.conversion-options .options-area {
        padding: 20px;
    }

    .custom-radius {
        border-radius: 10px !important;
    }

    .conversion-options h5 {
        font-size: 18px;
    }

    .navbar-toggler {
        margin-top: 25px;
    }

    .package {
        padding: 0px;
        margin-bottom: 10px;
    }

    .experience-wrap h3 {
        line-height: 30px;
        font-size: 20px;
        padding-bottom: 10px;
    }

    .search-box-btn button {
        padding: 10px 39px !important;
        margin-bottom: 0px !important;
    }

    .search-box-btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .map-area label.col-sm-3.col-form-label {
        width: 100%;
    }

    button.btn.btn-secondary.ms-auto.scan-btn,
    button.btn.btn-secondary.ms-auto.scan-btn-add {
        width: 100%;
    }

    .packages-section {
        padding: 0px;
        margin-top: 40px;
    }

    .packages-section h3 {
        font-size: 20px;
        padding-bottom: 10px;
        line-height: 28px;
    }

    .packages-section p {
        line-height: 24px;
    }

    .tab {
        padding: 14px 48px;
    }

    .packege-detail {
        min-height: auto;
    }

    .header .col-md-6.col-sm-12.col-12 {
        height: 0;
    }

    .mobile-wraper {
        height: 0;
    }

    .header .nav-link {
        line-height: 30px;
    }

    .login-menu li.nav-items {
        line-height: 30px;
    }

    .header {
        top: 0;
    }

    .logo img {
        width: 100%;
        max-width: 197px;
    }

    .footer-links ul:first-child {
        padding-left: 0px;
    }

    .footer {
        padding-top: 70px;
        text-align: center;
    }

    .footer .row:first-child {
        padding-bottom: 20px;
    }

    .up-arrow img {
        float: unset;
        width: 8%;
        margin: 0 auto;
    }

    .up-arrow img {
        animation: unset;
    }



    .header nav.navbar.navbar-expand-lg {
        justify-content: flex-end !important;
        margin-top: -64px;
    }

    body h1 {
        font-size: 42px;
    }

    .search-bar button {
        padding: 10px 14px;
        font-size: 12px;
    }

    .input-container i.fas.fa-link {
        font-size: 18px;
        padding-left: 0px;
    }

    .search-bar input[type="text"],
    .search-bar input[type="text"]::placeholder {
        font-size: 14px;
        padding-left: 8px;
        letter-spacing: 2px;
    }

    .input-container {
        padding: 8px;
        width: 40%;
        justify-content: space-between;
    }

    .grid-item h2 {
        font-size: 19px !important;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .grid-item p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .pdfonline-wrap h2 {
        font-size: 18px;
        padding-top: 0px;
        padding-bottom: 20px;
        line-height: 28px;
    }

    .grid-item h2 {
        padding-bottom: 0px;
        padding-top: 10px;
    }

    .grid-item {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }

    .image-section img {
        width: 20%;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    }

    .image-section img {
        width: 20%;
    }

    body h3 {
        font-size: 18px;
    }

    body h4 {
        font-size: 18px;
    }

    .experience-wrap h4 {
        padding-top: 4px;
        padding-bottom: 5px;
    }

    .feature-wrap h3 {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .feature-box h5 {
        font-size: 16px;
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .feature-wrap .col-md-3::before {
        right: 0;
        bottom: -11px;
        width: 100%;
        height: 1px;
    }

    .feature-wrap .col-md-3:last-child::before {
        display: block;
    }

    .feature-wrap .col-md-3 {
        margin-bottom: 30px;
    }

    .virtual-wrap {
        margin-top: 20px;
        padding-bottom: 0;
    }

    .benefits-wrap {
        margin-top: 30px;
    }

    .virtual-wrap h3,
    .virtual-wrap span,
    .virtual-wrap h4,
    .benefits-wrap h3 {
        padding-bottom: 10px;
    }

    .chose-wrap {
        padding-top: 10px;
    }

    .chose-wrap h3,
    .feature-wrap h3,
    .virtual-wrap h3,
    .benefits-wrap h3,
    body h3,
    .user-wrap h3,
    .broswer-wrap h3,
    .faq-wrap h3,
    .reach-wrap h3 {
        font-size: 22px;
        line-height: 32px;
    }

    .testimonial-item {
        padding: 0;
    }

    .testimonial-item h3 {
        font-size: 18px;
    }

    .testimonial-content i.fa-regular.fa-user {
        padding-bottom: 20px;
    }

    .icon-container {
        margin-bottom: 0;
    }

    .tabs {
        margin-bottom: 25px;
    }

    .user-wrap {
        padding-top: 10px;
    }

    .testimonial-item::before {
        display: none;
    }

    .testimonial-item p {
        margin-bottom: 0px;
    }

    .user-wrap p {
        padding-bottom: 10px;
    }

    .broswer-wrap {
        margin-top: 50px;
    }

    .plan-details h3,
    .plan-details h4 {
        padding-bottom: 5px;
    }

    .contact-form .form-row .form-group:last-child {
        width: 100%;
    }

    .search-bar {
        margin-top: 20px !important;
    }
}

@media only screen and (max-width:500px) {

    .footer-links ul,
    .footer-links {
        display: block;
    }

    button.navbar-toggler {
        width: auto;
    }

    .header nav ul {
        border-radius: 10px;
        top: 63px;
        display: block !important;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    body h1 {
        font-size: 18px;
        line-height: 32px;
    }

    .hero-section-wrap p {
        font-size: 16px;
    }

    .useage span {
        font-size: 12px;
    }

    .hero-section-wrap {
        padding-top: 110px;
    }

    .footer-links ul {
        padding-left: 0px;
    }

    .input-container {
        width: 100%;
    }
}

@media only screen and (max-width:480px) {
    .options-btn {
        margin-bottom: 10px;
    }

    .benefit-box {
        width: 100%;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    .grid-item p {
        font-size: 14px;
    }

    .grid-item h2 {
        font-size: 30px !important;
        line-height: 40px;

    }

    .grid-item {
        padding-left: 20px;
        padding-right: 15px;
    }



    .image-section.mobile-div {
        display: flex !important;
    }

    .grid-item.mobile-div {
        display: block !important;
    }

    .desktop-div {
        display: none !important;
    }

    .icon {
        width: 30px;
        height: 30px;
    }

    .footer ul li a {
        font-size: 14px;
        line-height: 1;
    }

    .image-section {
        padding-bottom: 30px;
        padding-top: 50px;
    }

    .grid-container .grid-item:first-child {
        border-top-left-radius: 0px;
    }

    .grid-container .image-section:nth-child(4) {
        border-top-right-radius: 0px;
    }

    .grid-container .image-section:nth-child(5) {
        border-bottom-left-radius: 0px;
    }

    .grid-container .grid-item:last-child {
        border-bottom-right-radius: 0px;
    }

    .header nav.navbar.navbar-expand-lg {
        margin-top: -70px;
        padding-right: 0px;
    }

    .header .navbar-toggler-icon {
        width: 1em;
        height: 1em;
        font-size: 24px;

    }
}

@media only screen and (min-width:1200px) {
    .container {
        max-width: 1170px !important;

    }
}



/* Custom Tooltip Text */


.custom-tooltip {
    position: relative;
    display: inline-block !important;
    cursor: pointer;
}

.custom-tooltip .custom-tooltip-text {
    font-size: 13px;
    font-weight: 400;
    visibility: hidden;
    width: 200px;
    /* Tooltip's default width */
    background-color: #000 !important;
    /* Fully opaque background */
    color: #fff !important;
    /* White text */
    text-align: left;
    line-height: 1.3;
    text-transform: none;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 65%;
    /* Position below the tooltip */
    left: 55%;
    /* Align to the bottom-left */
    transform: translateY(8px);
    /* Add some spacing */
    opacity: 0;
    /* Hidden initially */
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Depth effect */
    white-space: normal;
    /* Allow text to wrap */
}

/* Custom Tooltip Arrow */
.custom-tooltip .custom-tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    /* Place the arrow at the bottom of the tooltip */
    left: 16px;
    /* Adjust to align with the text */
    transform: translateX(-50%);
    /* Center the arrow horizontally */
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
    /* Black arrow pointing up */
}


/* Show Tooltip on Hover */
.custom-tooltip:hover .custom-tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Dynamic Height Handling */
.custom-tooltip .custom-tooltip-text {
    max-width: 300px;
    /* Optional: limit tooltip width */
    word-wrap: break-word;
    /* Break long words */
}





/*admin  Login page css */
.modern-design .btn.btn-dark {
    background: #463b94 !important;
    border: 1px solid #463b94 !important;
}

/* App Dashboard css */

.aside,
.aside:not(.open):hover {
    background-color: #00145A;
}

.aside .navigation-link.active,
.aside .navigation-link:hover {
    background-color: #463B94;
}

.light-purple {
    background: #463B94;
}

.light-purple.dashboard {
    background: #e1e1ff;
}

.dark-purple.dashboard {
    background: #d8e1ff;
}

.dark-purple {
    background: #00145A;
}

.light-purple svg,
.dark-purple svg {
    /* fill: #fff; */
}

.apex-chart .apexcharts-theme-light .apexcharts-menu-item:hover {
    background-color: #8074D7;
    color: #fff;
}

.DateInput_input {
    font-size: 16px;
}

.DateRangePickerInput_clearDates {
    padding: 0px !important;
}

.DateRangePickerInput_arrow_svg {
    height: 15px;
    width: 15px;
    position: relative;
    right: 10px;
}

.profile-detail svg {
    fill: #fff;
}

.modern-design .btn.btn-outline-success {
    color: #463B94;
}

.modern-design .header,
.wrapper footer.footer {
    background: #463B94 !important;
}

button.btn.btn-light-success.me-3.light-purple {
    color: #fff;
}

button.btn.btn-light-success.me-3.light-purple:hover {
    background: #00145A;
}

.plan-heading {
    margin-bottom: 15px;
}

.modern-design .subheader {
    margin-left: 0rem;
}

.subheader.row,
button.auth-btn,
a.auth-btn.border-light {
    background: #463B94 !important;
}

button.auth-btn,
a.auth-btn.border-light {
    color: #fff !important;
    border: 1px solid #463B94;
}

button.auth-btn:hover,
a.auth-btn.border-light:hover {
    background-color: #00145A !important;
}

.send-verification:hover {
    color: #00145A;
}

.form-control:focus {
    outline: none !important;
}

.auth-btn:hover,
a.btn.btn-lg.btn-light-dark.rounded-1.w-100:hover {
    background: #463b94;
    border: 1px solid #463b94;
}

.scroll-margin ul {
    padding-left: 0;
}

.scroll-margin ul {
    list-style-type: none;
    padding: 0;
}

.scroll-margin ul p ul li ul {
    padding-left: 20px;
}